'use client';

import { IconButton, Paper } from '@mui/material';
import { Box } from '@radix-ui/themes';
import { MenuIcon } from 'lucide-react';
import { useRouter } from 'next/navigation';
import { FC } from 'react';
import * as React from 'react';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';

export interface HorizontalNavigationProps {
    className?: string;
}

const HorizontalNavigationMobile: FC<HorizontalNavigationProps> = ({ className }) => {
    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
    const open = Boolean(anchorEl);
    const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };

    const router = useRouter();
    const handleGo = (href: string) => {
        router.push(href);
        setAnchorEl(null);
    };

    return (
        <Box className={className}>
            <IconButton
                className='float-right bg-gray-100 mr-4'
                id='basic-button'
                aria-controls={open ? 'basic-menu' : undefined}
                aria-haspopup='true'
                aria-expanded={open ? 'true' : undefined}
                onClick={handleClick}
            >
                <MenuIcon />
            </IconButton>

            <Menu
                id='basic-menu'
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
                MenuListProps={{ 'aria-labelledby': 'basic-button' }}
                className='w-full'
            >
                <Paper sx={{ width: '100vw', height: '100%', boxShadow: 'none' }}>
                    <MenuItem onClick={() => handleGo('/')}>
                        Home
                    </MenuItem>
                    <MenuItem onClick={() => handleGo('/catalog')}>
                        Catalog
                    </MenuItem>
                    <MenuItem onClick={() => handleGo('/about')}>
                        About Us
                    </MenuItem>
                    <MenuItem onClick={() => handleGo('/contacts')}>
                        Contacts
                    </MenuItem>
                </Paper>
            </Menu>
        </Box>
    );
}

export default HorizontalNavigationMobile;