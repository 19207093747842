'use client'

import React, { FC } from 'react';
import Link from 'next/link';
import { usePathname } from 'next/navigation';

import { Breadcrumbs } from '@mui/material';

const BreadcrumbsTop: FC = () => {
    const paths = usePathname()
    const pathNames = paths.split('/').filter( path => path ).slice(0, 1);

    return (
        <Breadcrumbs
            maxItems={6}
            separator='›'
            aria-label='breadcrumb'
            className='flex items-center mx-3'
        >
            <Link
                href='/'
                className='uppercase tracking-widest text-tiny font-semibold hover:text-sky-500 ease-in duration-300 mb-3 mr-1'
            >
                Home
            </Link>
            {pathNames.length > 0 && pathNames.map((link, index) =>
                <Link
                    key={index}
                    href={`/${pathNames.slice(0, index + 1).join('/')}`}
                    className='uppercase tracking-widest text-tiny font-semibold hover:text-sky-500 ease-in duration-300 mb-3 mr-1'
                >
                    {link}
                </Link>
            )}
        </Breadcrumbs>
    )
};

export default BreadcrumbsTop;